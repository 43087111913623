import React, { useRef, useState, useEffect } from "react";
import api from "../../api";
import { Table, Button, Popconfirm, message, Row, Col } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import MyModal from "@Components/MyModal";
import Detail from "./Detail";
import "./User.scss";
const User = (props) => {
    const { history } = props;
    // state
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);

    // refs
    const myModal = useRef(null);

    // functions

    const fetchData = async () => {
        let res = await api.get("/app");
        let { status, result } = res.data;
        if (status) {
            setData(result);
            setLoading(false);
        }
    };

    const deleteApp = async (id) => {
        let res = await api.delete(`/app/${id}`);
        const { status, msg } = res.data;
        if (status) {
            message.success(msg);
            fetchData();
        } else {
            message.error("Xoá thất bại, vui lòng thử lại sau");
        }
    };

    const fetchCustomers = async () => {
        let res = await api.get("/customer");
        let { status, result } = res.data;
        if (status) {
            setCustomers(result);
        }
    };

    const renderCustomers = () => {
        if (customers.length !== 0) {
            return customers.map((item, index) => {
                return (
                    <div className="customer-item">
                        {item.name}
                    </div>
                );
            });
        }
    };

    // defines
    const columns = [
        {
            title: "App id",
            dataIndex: "appid",
            key: "id",
        },
        {
            title: "Tên app",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Key bí mật",
            dataIndex: "secretkey",
            key: "secretkey",
        },
        {
            title: "Khách hàng",
            dataIndex: "customerid",
            key: "customer",
            render: (text, record) => {
                return <div>{record.customerid && record.customerid.name}</div>;
            },
        },
        {
            title: "Actions",
            align: "center",
            render: (text, record, index) => {
                return (
                    <div>
                        <a
                            style={{ marginRight: "10px" }}
                            onClick={() =>
                                props.history.push(`/app-detail/${record._id}`)
                            }
                        >
                            <EditOutlined />
                        </a>

                        <Popconfirm
                            onConfirm={() => deleteApp(record._id)}
                            title="Bạn có chắc chắn muốn xoá không?"
                        >
                            <a>
                                <DeleteOutlined />
                            </a>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    // useEffect
    useEffect(() => {
        fetchCustomers();
        fetchData();
    }, []);
    return (
        <div className="user-container">
            {/* <div className="actions">
                <Button
                    className="actions__btn-add"
                    type="primary"
                    onClick={() => {
                        history.push("/app-detail");
                    }}
                >
                    Thêm mới
                </Button>
            </div>
            <div className="table-custom">
                <Table
                    loading={loading}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                />
            </div> */}
            <div>Chọn khách hàng</div>
            <div className="customer-list">{renderCustomers()}</div>
            <MyModal ref={myModal} />
        </div>
    );
};

export default User;
