import axios from "axios";
import Config from "@Config";

let token = JSON.parse(localStorage.getItem("token"));
let api = axios.create({
    baseURL: Config.url,
    headers: {
        "Content-Type": "application/json",
    },
});

api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

!token &&
    api.interceptors.request.use(function (config) {
        token = JSON.parse(localStorage.getItem("token"));
        config.headers.Authorization = `Bearer ${token}`;
        // api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        return config;
    });

api.interceptors.response.use(
    function (res) {
        return res;
    },
    function (error) {
        window.location.href = "/login";
        localStorage.removeItem("token");
        return Promise.reject(error);
    }
);

export default api;
