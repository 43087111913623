import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import { Modal, Button } from "antd";

const MyModal = React.forwardRef((props, ref) => {
    const [visitModal, setVisitModal] = useState(false);
    const [view, setView] = useState(null)
    const [title, setTitle] = useState(null)
    useImperativeHandle(ref, () => ({
        handleVisitModal: (title, view) => {
            setVisitModal(true)
            setView(view)
            setTitle(title)
        },
        handleCancel: () =>{
            setVisitModal(false)
        }
    }));

    const handleCancel = () =>{
        setVisitModal(false)
    }

    return (
        <Modal
            ref={ref}
            title={title || "Modal"}
            visible={visitModal}
            onCancel={handleCancel}
            footer={null}
        >
           {view}
        </Modal>
    );
});

export default MyModal;
