import React, { useRef, useState, useEffect } from "react";
import api from "../../api";
import { Table, Button, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import MyModal from "@Components/MyModal";
import Detail from "./Detail";
import "./Home.scss";
const Home = (props) => {
    const { history } = props;
    // state
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // refs
    const myModal = useRef(null);

    // functions
    const handleShowMyModal = (type, id = null) => {
        myModal.current.handleVisitModal(
            type,
            <Detail
                id={id}
                fetchData={fetchData}
                handleCancel={myModal.current.handleCancel}
            />
        );
    };

    const fetchData = async () => {
        let res = await api.get("/customer");
        let { status, result } = res.data;
        if (status) {
            setData(result);
            setLoading(false);
        }
    };

    const deleteCustomer = async (id) => {
        let res = await api.delete(`/customer/${id}`);
        const { status } = res.data;
        if (status) {
            message.success(res.data.message);
            fetchData();
        } else {
            message.error("Xoá thất bại, vui lòng thử lại sau");
        }
    };

    // defines
    const columns = [
        {
            title: "Id Customer",
            dataIndex: "_id",
            key: "id",
        },
        {
            title: "Customer's name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Actions",
            align: "center",
            render: (text, record, index) => {
                return (
                    <div>
                        <a
                            style={{ marginRight: "10px" }}
                            onClick={() =>
                                handleShowMyModal("Cập nhật", record._id)
                            }
                        >
                            <EditOutlined />
                        </a>

                        <Popconfirm
                            onConfirm={() => deleteCustomer(record._id)}
                            title="Bạn có chắc chắn muốn xoá không?"
                        >
                            <a>
                                <DeleteOutlined />
                            </a>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    // useEffect
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="home-container">
            <div className="actions">
                <Button
                    className="actions__btn-add"
                    type="primary"
                    onClick={() => {
                        handleShowMyModal("Thêm mới");
                    }}
                >
                    Thêm mới
                </Button>
            </div>
            <div className="table-custom">
                <Table
                    scroll={{ y: document.body.offsetHeight - 250 }}
                    loading={loading}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                />
            </div>
            <MyModal ref={myModal} />
        </div>
    );
};

export default Home;
