import React from "react";
import "./App.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { authHOC } from "./utils/auth";
import Login from "@Containers/Login";
import Main from "@Containers/Main";
import Key from "@Containers/Key";
function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={authHOC(Login)} />
                <Route path="/" component={authHOC(Main)} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
