import React, { useState, useEffect } from "react";
import { Layout, Menu, Dropdown, Button } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import "./Main.scss";
import { mainRoutes } from "../../../routes";
import { Route, Link, withRouter, Switch } from "react-router-dom";
import Key from "../Key";
import Home from "../Home";
import {authHOC} from '../../../utils/auth'

const { Header, Footer, Sider, Content } = Layout;

let MenuItem = [
    {
        name: "Quản lý khách hàng",
        path: "/",
        icon: "UserOutlined",
    },
    {
        name: "Quản lý app",
        path: "/app",
        icon: "UserOutlined",
    },
    {
        name: "Quản lý user",
        path: "/user",
        icon: "UserOutlined",
    },
];

const Main = (props) => {
    const { history } = props;
    const menu = (
        <Menu>
            <Menu.Item onClick={() => logOut()}>Đăng xuất</Menu.Item>
        </Menu>
    );
    const [collapsed, setCollapsed] = useState(false);
    const toggle = () => {
        setCollapsed(!collapsed);
    };
    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("me");
        history.push("/login");
    };
    return (
        <Layout className="main-container">
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo" />
                <Menu theme="dark" mode="inline">
                    {MenuItem.map((item, index) => (
                        <Menu.Item
                            key={index}
                            onClick={() => props.history.push(item.path)}
                        >
                            {item.name}
                        </Menu.Item>
                    ))}
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{
                        padding: 0,
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    {React.createElement(
                        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                        {
                            className: "trigger",
                            onClick: toggle,
                        }
                    )}
                    <div style={{ padding: "0 24px" }}>
                        <Dropdown overlay={menu}>
                            <Button
                                icon={<UserOutlined />}
                                shape="circle-outline"
                            />
                        </Dropdown>
                    </div>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: "24px 16px",
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {mainRoutes.map((item, index) => (
                        <Route
                            key={index}
                            path={item.path}
                            exact={item.exact}
                            component={authHOC(item.component)}
                        />
                    ))}
                    {/* <Route path="/" exact={true} component={Home}/>
                        <Route path="/key" exact={true} component={Key}/> */}
                </Content>
            </Layout>
        </Layout>
    );
};

export default withRouter(Main);
