import Home from "@Containers/Home";
import App from "@Containers/App";
import AppDetail from "@Containers/App/Detail";
import User from "@Containers/User";

export const mainRoutes = [
    {
        path: "/user",
        exact: true,
        component: User,
    },
    {
        path: "/app-detail/:id",
        exact: true,
        component: AppDetail,
    },
    {
        path: "/app-detail",
        exact: true,
        component: AppDetail,
    },
    {
        path: "/app",
        exact: true,
        component: App,
    },
    {
        path: "/",
        exact: true,
        component: Home,
    },
];
