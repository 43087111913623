import React from "react";
import { Form, Input, Button, Checkbox, message } from "antd";

import api from "@Modules/api";
import "./Login.scss";

const Login = (props) => {
    const { history } = props;
    const onFinish = async (values) => {
        const res = await api.post("/admin/login", {
            username: values.username,
            password: values.password,
        });
        const { status, token } = res.data;
        const msg = res.data.message;
        if (status) {
            message.success(msg);

            localStorage.setItem("token", JSON.stringify(token));
            history.push("/");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    };

    return (
        <Form
            className="form-login"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                label="Username"
                name="username"
                rules={[
                    { required: true, message: "Please input your username!" },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    { required: true, message: "Please input your password!" },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default Login;
