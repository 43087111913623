import React, { useState, useRef } from "react";
import { Input, Button, message } from "antd";
import { useEffect } from "react";

import api from "@Modules/api";
import "./Home.scss";

const Detail = (props) => {
    // define
    const { fetchData, handleCancel, id } = props;

    // state
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);

    // function
    const handleChange = (e) => {
        setContent(e.target.value);
    };
    const submitValue = async () => {
        if (content) {
            setLoading(true);
            if (!id) {
                const res = await api.post("customer", {
                    name: content,
                });
                if (res.data.status) {
                    setLoading(false);
                    fetchData();
                    handleCancel();
                    message.success("Tạo khách hàng thành công");
                } else {
                    setLoading(false);
                    message.error(res.data.message);
                }
            } else {
                const res = await api.put(`customer/${id}`, {
                    name: content,
                });
                if (res.data.status) {
                    setLoading(false);
                    fetchData();
                    handleCancel();
                    message.success("Cập nhật khách hàng thành công");
                } else {
                    setLoading(false);
                    message.error(res.data.message);
                }
            }
        }
    };

    const fetchOne = async () => {
        let res = await api.get(`/customer/${id}`);
        let { status, result } = res.data;
        if (status) {
            setContent(result.name);
        }
    };

    // useeffect
    useEffect(() => {
        if (id) {
            fetchOne();
        }
    }, []);
    return (
        <div className="home-detail-container">
            <Input
                required
                value={content}
                placeholder="Thêm mới"
                onChange={handleChange}
            />
            <div className="actions">
                <Button
                    onClick={() => handleCancel()}
                    style={{ marginRight: "5px" }}
                >
                    Huỷ
                </Button>
                <Button
                    loading={loading}
                    type="primary"
                    onClick={() => submitValue()}
                >
                    {id?"Cập nhật":"Tạo mới"}
                </Button>
            </div>
        </div>
    );
};

export default Detail;
