import React, {useMemo} from "react";
import { withRouter } from "react-router-dom";


export const authHOC = (Component) => {
    const auth = (props) => {
        const { history } = props;
        const isLogin = !!window.localStorage.getItem("token");
        if (isLogin && !window.location.pathname.includes("/login")) {
                return <Component {...props} />;
        } else {
            if (isLogin && window.location.pathname.includes("/login")) {
                history.push("/");
            }
            if (!isLogin && !window.location.pathname.includes("/login")) {
                history.push("/login");
            }
        }
        return <Component {...props} />;
    };

    return withRouter(auth);
};