import React, { useState, useRef } from "react";
import { Input, Button, message, Form, Select } from "antd";
import { useEffect } from "react";

import api from "@Modules/api";
import { layout, tailLayout } from "@Utils/ConfigLayout";
import "./App.scss";

const { Option } = Select;

const Detail = (props) => {
    // define
    const { fetchData, handleCancel } = props;
    const [form] = Form.useForm();
    const { id } = props.match.params;
    // state
    const [appData, setAppData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [appId, setAppId] = useState(null);
    const [secretKey, setSecretKey] = useState(null);
    // function
    const handleChange = (e) => {
        if (e.target.name === "appid") {
            setAppId(e.target.value);
        }
        if (e.target.name === "secretkey") {
            setSecretKey(e.target.value);
        }
    };
    const submitValue = async (values) => {
        let { name, description, customerid, webhook } = values;
        setLoading(true);
        if (!id) {
            const res = await api.post("app", {
                appid: appId,
                name,
                webhook,
                description,
                secretkey: secretKey,
                customerid,
            });
            if (res.data.status) {
                setLoading(false);
                props.history.goBack();
                message.success(res.data.msg);
            } else {
                setLoading(false);
                message.error(res.data.msg);
            }
        } else {
            const res = await api.put(`app/${id}`, {
                appid: appId,
                name,
                webhook,
                description,
                secretkey: secretKey,
                customerid,
            });
            if (res.data.status) {
                setLoading(false);
                props.history.goBack();
                message.success(res.data.msg);
            } else {
                setLoading(false);
                message.error(res.data.msg);
            }
        }
    };

    const fetchOne = async () => {
        let res = await api.get(`/app/${id}`);
        let { status, result } = res.data;
        if (status) {
            setAppId(result.appid);
            setSecretKey(result.secretkey);
            setAppData({
                name: result.name,
                description: result.description,
                customerid: result.customerid,
            });
            form.setFieldsValue({
                name: result.name,
                description: result.description,
                customerid: result.customerid,
                webhook: result.webhook
            });
        }
    };

    const fetchCustomers = async () => {
        let res = await api.get("/customer");
        let { status, result } = res.data;
        if (status) {
            setCustomers(result);
        }
    };

    const renderSelectCustomers = () => {
        if (customers.length !== 0) {
            return customers.map((item, index) => {
                return <Option value={item._id}>{item.name}</Option>;
            });
        }
    };

    const randomString = (length, type) => {
        var result = "";
        var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        if (type === "appid") {
            setAppId(result);
        }
        if (type === "secretkey") {
            setSecretKey(result);
        }
        return result;
    };

    // useeffect
    useEffect(() => {
        fetchCustomers();
        if (id) {
            fetchOne();
        }
    }, []);
    return (
        <div className="app-detail-container">
            <Form
                form={form}
                {...layout}
                name="basic"
                onFinish={submitValue}
                // onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="App ID"
                    // name="appid"
                    rules={[
                        {
                            required: true,
                            message: "Please input app id!",
                        },
                    ]}
                >
                    <div style={{ display: "flex" }}>
                        <Input
                            required
                            name="appid"
                            value={appId}
                            onChange={handleChange}
                        />
                        <Button
                            onClick={() => {
                                randomString(10, "appid");
                            }}
                            style={{ marginLeft: "10px" }}
                        >
                            Ngẫu nhiên
                        </Button>
                    </div>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input app's name!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input />
                </Form.Item>
                <Form.Item label="Webhook" name="webhook">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Secret key"
                    // name="secretkey"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: "Please input app's secret key!",
                    //     },
                    // ]}
                >
                    <div style={{ display: "flex" }}>
                        <Input
                            name="secretkey"
                            required
                            value={secretKey}
                            onChange={handleChange}
                        />
                        <Button
                            onClick={() => {
                                randomString(10, "secretkey");
                            }}
                            style={{ marginLeft: "10px" }}
                        >
                            Ngẫu nhiên
                        </Button>
                    </div>
                </Form.Item>
                <Form.Item
                    label="Customer"
                    name="customerid"
                    rules={[
                        {
                            required: true,
                            message: "Please input app's secret key!",
                        },
                    ]}
                >
                    <Select
                        placeholder="Select a option and change input text above"
                        // onChange={this.onGenderChange}
                        allowClear
                    >
                        {renderSelectCustomers()}
                    </Select>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Detail;
